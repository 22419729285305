export default class Select{
  elem;
  parent;

  Select(){
    let slc = this.elem.querySelectorAll('option');
    let slcPar = this.parent;
    let pcked = this.parent.querySelector('.picked');
    let list, ul, val = [];

    list = document.createElement('div');
    list.classList.add('list');
    slcPar.append(list);
    ul = document.createElement('ul');
    list.append(ul);

    slc.forEach((e,i)=>{
      
      if(i !== 0){
        val[i] = document.createElement('li');
        val[i].innerHTML = e.getAttribute('value');     
        ul.append(val[i]);

        val[i].addEventListener('click',function(){
          slc.forEach((a)=>{
            a.removeAttribute('selected');
          })
          e.setAttribute('selected',true);
          slcPar.removeAttribute('data-status')
          pcked.innerHTML = e.getAttribute('value'); 
        });

      }
    });

    slcPar.addEventListener('click',function(){
      list.classList.toggle('show')
    });
  }
}