export default class Preloader{
  nxt = false;

	preload(asset){
		let loadedArr = {};
    let totImg = asset.length;
    let cur = 0, percent = 0;;
    let per = document.querySelector(".percentage span");

		const loadImage = (url) => new Promise((resolve, reject) => {
      const img = new Image();
      img.addEventListener('load', () => resolve(img));
      img.addEventListener('error', (err) => reject(err));
      img.src = url;
    });

		asset.forEach((p,i) => {
      loadImage(p)
      .then(img => {
      	loadedArr.id = i;
      	loadedArr.url = img.src;
        cur++;
        percent = parseInt(((cur/totImg)*100)/2);
        per.innerHTML = parseInt(percent);
        if(percent == 50){
          this.nxt = true;  
        }
      })
      .catch(err => console.error(`${p} ${i}`));
    }); 
    return this.nxt;
	}
}