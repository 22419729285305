export default class Modal{
  elem;

  init(){
    let modals = this.elem;
    let modalContainer = document.createElement('div');
    modalContainer.className = 'modal-container';

    let modalContent = document.createElement('div');
    modalContent.className = 'modal-content';
    modalContainer.append(modalContent);

    let modalImg = document.createElement('img');
    let modalClose = document.createElement('div');
    modalClose.className = 'close';
    modalContent.append(modalClose);
    modalContent.append(modalImg);

    document.querySelector('body').append(modalContainer);
    modalClose.addEventListener('click',hide)

    if(modals.length > 0){
      modals.forEach(function(e,i){
        e.addEventListener('click',pop);
      });
    }

    function pop(e){
      e.preventDefault();
      let pop = this.getAttribute('modal-img-open');
      modalImg.src = pop;
      modalContainer.style.display = "block";
    }

    function hide(){
      modalContainer.style.display = "none";
    }
  }
}