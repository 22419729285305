import $ from "jquery";  

export default class Email{
  label;

  // careers
  btn;
  send;
  form;
  formX;
  data;

  sendemail() {
    let lbl = this.label;

    lbl.classList.remove('error');
    lbl.classList.add('show');

    $.ajax({
      url: "./php/email.php",
      type: 'POST',  // http method
      data: {
        emailType: 'contact',
        gRecaptchaResponse: $("#g-recaptcha-response").val(),
        action: $('action').val(),
        name: $("#contact-name").val(),
        mobile_number: $("#contact-mobile").val(),
        email: $("#contact-email").val(),
        inquiry:$("#inquiry").val(),
        message:$("#contact-message").val()
      },  // data to submit
      success: function (data, status, xhr) {
        window.location.href = "thank-you.html";
      },
      error: function (jqXhr, textStatus, errorMessage) {
        console.log(jqXhr, textStatus, errorMessage)
        lbl.innerHTML = `Error: ${errorMessage}`;
        lbl.classList.add('error');
      }
    });
    return false;
  }

  showForm(){
    let applyBTNs = this.btn;
    let send = this.send;
    let frm = this.form;
    let close = this.formX

    applyBTNs.forEach((btn,i)=>{
      btn.addEventListener('click',function(e){
        e.preventDefault();
        const jobtype = btn.getAttribute('data-career').replace('-',' ');

        document.querySelector('#job').value = jobtype;
        document.querySelector('body').style.overflow = 'hidden';
        frm.classList.add('show');

        frm.querySelector('.applyFor').innerHTML = `You're applying for<br><span>${jobtype}</span>`
      }); 
    });

    close.addEventListener('click',function(){
      document.querySelector('body').style.overflow = 'initial';
      frm.classList.remove('show');
    });
  }

  sendApplication(){
    let lbl = this.label;
    let $this = this.data;
    let formdata = new FormData($this);

    lbl.innerHTML = "Sending...";
    $this.querySelector('#first-name').disabled = true;
    $this.querySelector('#mobile-number').disabled = true;
    $this.querySelector('#last-name').disabled = true;
    $this.querySelector('#email').disabled = true;
    $this.querySelector('#career-file').disabled = true;
    $this.querySelector('.btn').disabled = true;
    $this.querySelector('.btn').innerHTML = "Sending..."
    $.ajax('./php/email_attachment.php', {
      type: 'POST',  // http method
      cache: false,
      data: formdata,
      contentType: false,
      processData: false,
      success: function (data, status, xhr) {
        $this.querySelector('#first-name').value = ''
        $this.querySelector('#mobile-number').value = '';
        $this.querySelector('#last-name').value = '';
        $this.querySelector('#email').value = '';
        $this.querySelector('#career-file').value = '';
        window.location.href = "thank-you.html";
      },
      error: function (jqXhr, textStatus, errorMessage) {
        lbl.innerHTML = `Error: ${errorMessage}`;
        lbl.classList.add('error');
      }
    });
    return false;
  }
}