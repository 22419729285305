/*IMPORTS*/

//scss
import './sass/style.scss';

// plugin js
import $ from "jquery";
import prllx from './parallax.js';
import slider from './slider.js';
import navigation from './menu.js';
import preloader from './preloader';
import modal from './modal';
import select from './select';
import email from './email';

// images 
import logo from './img/c3-logo.png';
import logoWhite from './img/c3-logo-white.png';
import './img/c3-interactive-social-share.jpg';

//import images
function importAll(r) {
  return r.keys().map(r);
}

//get image order
function id(i){
  const pathArray = i.split("/");
  const fileName = pathArray[pathArray.length-1];
  const id = parseInt(fileName.slice(0,2)) - 1;
  return id;
}

function fn(i){
  const pathArray = i.split("/");
  const fileName = pathArray[pathArray.length-1];
  const name = fileName.split('.')
  return name[0];
}

// import website with param
let webArr = [];
let websites = importAll(require.context('./img/works/', false, /\.(png|jpe?g|svg)$/));
websites.forEach((img,i)=>{
  webArr[id(img)] = {
    'url':img,
  };

  if(id(img) == 0){
    webArr[id(img)].name = 'San Miguel Corporation';
    webArr[id(img)].link = 'https://www.sanmiguel.com.ph/';
  }else if(id(img) == 1){
    webArr[id(img)].name = 'Kia philippines';
    webArr[id(img)].link = 'https://www.kia.com/ph/main.html';
  }else if(id(img) == 2){
    webArr[id(img)].name = 'Red Lab Gallery'; 
    webArr[id(img)].link = 'https://www.redlabgalleryph.com/';
  }else if(id(img) == 3){
    webArr[id(img)].name = 'CBC ilink';
    webArr[id(img)].link = 'https://cbc.ilink.network/';
  }else if(id(img) == 4){
    webArr[id(img)].name = 'Alfred';
    webArr[id(img)].link = 'https://butalfred.com/';
  }
});

// slider module
let sldrW = new slider;
sldrW.imgs = webArr;
sldrW.type = 'works';
sldrW.slider();

// import works inner
let worksArr = [];
let works = importAll(require.context('./img/works/inner', false, /\.(png|jpe?g|svg)$/));
let workGrid = document.querySelector('.works-grid');
if(workGrid){
  works.forEach((img,i)=>{
    let wi = document.createElement('img');
    wi.src = img;
    let wa = document.createElement('a');
    wa.setAttribute('href','#');
    wa.setAttribute('index',i)

    
    switch(i){
      case 0:
        wa.setAttribute('href','https://www.smfb.com.ph/');
        wa.setAttribute('target','_blank');
      break;
      case 1:
        wa.setAttribute('modal-img-open','assets/src/img/works/edm/edm-pop-5.png');
        wa.classList.add('modal');
      break;
      case 2:
        wa.setAttribute('href','https://smcglobalpower.com.ph/');
        wa.setAttribute('target','_blank');
      break; 
      case 4:
        wa.setAttribute('href','https://gkclark.com/');
        wa.setAttribute('target','_blank');
      break; 
      case 5:
        wa.setAttribute('modal-img-open','assets/src/img/works/edm/edm-pop-3.png');
        wa.classList.add('modal');
      break;
      case 7:
        wa.setAttribute('modal-img-open','assets/src/img/works/edm/edm-pop-2.png');
        wa.classList.add('modal');
      break;
      case 8:
        wa.setAttribute('href','https://www.sanmiguel.com.ph/');
        wa.setAttribute('target','_blank');
      break;
      case 9:
        wa.setAttribute('href','https://www.kia.com/ph/main.html');
        wa.setAttribute('target','_blank');
      break;
      case 10:
        wa.setAttribute('href','https://www.maxus.com.ph/');
        wa.setAttribute('target','_blank');
      break;
      case 11:
        wa.setAttribute('href','https://www.sanmiguelbrewery.com.ph/welcome');
        wa.setAttribute('target','_blank');
      break;
    }
    

    wa.append(wi)
    if(i % 3 === 0){
      workGrid.querySelector('.grid-3').append(wa);
    }else if(i % 2 === 0){
      workGrid.querySelector('.grid-2').append(wa);
    }else{
      workGrid.querySelector('.grid-1').append(wa);
    }
  });
}

// modal
let m = new modal;
m.elem = document.querySelectorAll('.modal');
m.init();

// who icons

// major works
import hive from './img/works/hive.png';
let imgHive = document.querySelector('img.hive');
if(imgHive){
  imgHive.src = hive;
}
//hive inner page
let hiveImg = importAll(require.context('./img/works/hive', false, /\.(png|jpe?g|svg)$/)); 

import ilink from './img/works/ilink.png';
let imgilink = document.querySelector('img.ilink');
if(imgilink){
  imgilink.src = ilink;
}
//ilink inner page
let ilinkImg = importAll(require.context('./img/works/ilink', false, /\.(png|jpe?g|svg)$/));

import alfred from './img/works/alfred.png';
let imgalfred = document.querySelector('img.alfred');
if(imgalfred){
  imgalfred.src = alfred;
}
//alfred inner page
let alfredImg = importAll(require.context('./img/works/alfred', false, /\.(png|jpe?g|svg)$/)); 

import edm from './img/works/edm.png';
let imgedm = document.querySelector('img.edm');
if(imgedm){
  imgedm.src = edm;
}
//edm inner page
let edmImg = importAll(require.context('./img/works/edm', false, /\.(png|jpe?g|svg)$/));

import web from './img/works/web.png';
let imgweb = document.querySelector('img.web');
if(imgweb){
  imgweb.src = web;
}
//hris inner page
let webImg = importAll(require.context('./img/works/web', false, /\.(png|jpe?g|svg)$/));

import hris from './img/works/hris.png';
let imghris = document.querySelector('img.hris');
if(imghris){
  imghris.src = hris;
}
//hris inner page
let hrisImg = importAll(require.context('./img/works/hris', false, /\.(png|jpe?g|svg)$/));

import smm from './img/works/smm.png';
let imgsmm = document.querySelector('img.smm');
if(imgsmm){
  imgsmm.src = smm;
}
//smm inner page
let smmImg = importAll(require.context('./img/works/smm', false, /\.(png|jpe?g|svg)$/));

// import client with param
let clientArr = [];
let clients = importAll(require.context('./img/logos/', false, /\.(png|jpe?g|svg)$/));
clients.forEach((img,i)=>{
  clientArr[id(img)] = {
    'url':img,
  };

  if(id(img) == 0){
    clientArr[id(img)].name = 'San Miguel Corporation';
    clientArr[id(img)].link = 'https://www.sanmiguel.com.ph/';
  }else if(id(img) == 1){
    clientArr[id(img)].name = 'San Miguel Food &amp Beverage Inc.';
    clientArr[id(img)].link = 'https://www.smfb.com.ph/';
  }else if(id(img) == 2){
    clientArr[id(img)].name = 'San Miguel Brewery Inc.';
    clientArr[id(img)].link = 'https://www.sanmiguelbrewery.com.ph/';
  }else if(id(img) == 3){
    clientArr[id(img)].name = 'San Miguel Top Frontier';
    clientArr[id(img)].link = 'https://topfrontier.com.ph/';
  }else if(id(img) == 4){
    clientArr[id(img)].name = 'San Miguel Properties';
    clientArr[id(img)].link = 'https://www.sanmiguelproperties.com.ph/';
  }else if(id(img) == 5){
    clientArr[id(img)].name = 'Petrogen Insurance';
    clientArr[id(img)].link = 'https://petrogen.com.ph/';
  }else if(id(img) == 6){
    clientArr[id(img)].name = 'Anchor Insurance Brokerag Corporation';
    clientArr[id(img)].link = 'https://aibc.com.ph/';
  }else if(id(img) == 7){
    clientArr[id(img)].name = 'San Miguel Global Power';
    clientArr[id(img)].link = 'https://smcglobalpower.com.ph/';
  }else if(id(img) == 8){
    clientArr[id(img)].name = 'Prosync';
    clientArr[id(img)].link = 'https://www.prosync.com.ph/';
  }else if(id(img) == 9){
    clientArr[id(img)].name = 'Philippines Airlines';
    clientArr[id(img)].link = 'https://www.philippineairlines.com/';
  }else if(id(img) == 10){
    clientArr[id(img)].name = 'Philippines Airlines - Mabuhay Miles';
    clientArr[id(img)].link = 'https://www.philippineairlines.com/en/about-us';
  }else if(id(img) == 11){
    clientArr[id(img)].name = 'Kia Philippines';
    clientArr[id(img)].link = 'https://www.kia.com/';
  }else if(id(img) == 12){
    clientArr[id(img)].name = 'Maxus Philippines';
    clientArr[id(img)].link = 'https://www.maxus.com.ph/';
  }else if(id(img) == 13){
    clientArr[id(img)].name = 'Red Lab Gallery';
    clientArr[id(img)].link = 'https://www.redlabgalleryph.com/';
  }else if(id(img) == 14){
    clientArr[id(img)].name = 'Gemik Clark';
    clientArr[id(img)].link = 'https://gkclark.com/';
  }
});

let sldrC = new slider;
sldrC.imgs = clientArr;
sldrC.type = 'clients';
sldrC.slider();

// proifle pics
let profiles = importAll(require.context('./img/profile/', false, /\.(png|jpe?g|svg)$/));

// branches pics
import './img/branches/trees.svg';
import sgBranch from './img/branches/singapore.svg';
import shgBranch from './img/branches/shanghai.svg';
import mnlBranch from './img/branches/manila.svg';
import './img/branches/singapore-banner.jpg';
import './img/branches/shanghai-banner.jpg';
import './img/branches/manila-banner.jpg';

let sgHover = document.querySelector('.sg');
let shgHover = document.querySelector('.shg');
let mnlHover = document.querySelector('.mnl');

if (sgHover && shgHover && mnlHover) {
  sgHover.src = sgBranch;
  shgHover.src = shgBranch;
  mnlHover.src = mnlBranch;
}

let brncLink = document.querySelector('.branch-link');
if(brncLink){
  let bLinks = brncLink.querySelectorAll('a');
  bLinks.forEach((e,i)=>{
    e.addEventListener('click',function(evt){
      if(this.getAttribute('href') == '#'){
        evt.preventDefault();
      }
    });
  });
}

//aboutus images
import aboutUs_phi from './img/about-us/manila.jpg';
import rizal from './img/about-us/rizal-monument.svg';
import aboutUs_sha from './img/about-us/shanghai.jpg';
import oriental_pearl from './img/about-us/oriental-pearl.svg';
import aboutUs_sin from './img/about-us/singapore.jpg';
import merlion from './img/about-us/merlion.svg';
import process_cta from './img/about-us/process.png';
import team_cta from './img/about-us/team.png';


let aUS_sha = document.querySelector('.aboutUs-sha');
if(aUS_sha){
  aUS_sha.src = aboutUs_sha
}
let aUS_sg = document.querySelector('.aboutUs-sg');
if(aUS_sg){
  aUS_sg.src = aboutUs_sin
}
let aUS_phi = document.querySelector('.aboutUs-ph');
if(aUS_phi){
  aUS_phi.src = aboutUs_phi
}
let cta_process = document.querySelector('.our-process-cta');
if(cta_process){
  cta_process.src = process_cta
}
let cta_team = document.querySelector('.our-team-cta');
if(cta_team){
  cta_team.src = team_cta
}
let icon_merlion = document.querySelector('.aboutUs-merlion');
if(icon_merlion){
  icon_merlion.src = merlion
}
let icon_oriental_pearl = document.querySelector('.aboutUs-oriental-pearl');
if(icon_oriental_pearl){
  icon_oriental_pearl.src = oriental_pearl
}
let icon_rizal = document.querySelector('.aboutUs-rizal');
if(icon_rizal){
  icon_rizal.src = rizal
}

// process images
import './img/about-us/process.jpg';
import process_planning from './img/about-us/planning.png';
import process_strategy from './img/about-us/strategy.png';
import process_execution from './img/about-us/execution.png';
import process_evaluation from './img/about-us/evaluation.png';
import team_accounts from './img/about-us/accounts.png';
import team_techprod from './img/about-us/tech-prod.png';
import team_creatives from './img/about-us/creatives.png';
import team_frontend from './img/about-us/front-end.png';

let aUS_prc_planning = document.querySelector('.aboutUs-process-planning');
if(aUS_prc_planning){
  aUS_prc_planning.src = process_planning
}
let aUS_prc_strategy = document.querySelector('.aboutUs-process-strategy');
if(aUS_prc_strategy){
  aUS_prc_strategy.src = process_strategy
}
let aUS_prc_execution = document.querySelector('.aboutUs-process-execution');
if(aUS_prc_execution){
  aUS_prc_execution.src = process_execution
}
let aUS_prc_evaluation = document.querySelector('.aboutUs-process-evaluation');
if(aUS_prc_evaluation){
  aUS_prc_evaluation.src = process_evaluation
}

let aUS_tm_acct = document.querySelector('.aboutUs-team-accounts');
if(aUS_tm_acct){
  aUS_tm_acct.src = team_accounts
}
let aUS_tm_creatives = document.querySelector('.aboutUs-team-creatives');
if(aUS_tm_creatives){
  aUS_tm_creatives.src = team_creatives
}
let aUS_tm_techprod = document.querySelector('.aboutUs-team-techprod');
if(aUS_tm_techprod){
  aUS_tm_techprod.src = team_techprod
}

let aUS_tm_frontend = document.querySelector('.aboutUs-team-frontend');
if(aUS_tm_frontend){
  aUS_tm_frontend.src = team_frontend
}

// team images
import './img/about-us/team.jpg';

//services images
import './img/services/services.jpg';
let services = importAll(require.context('./img/services/', false, /\.(png|jpe?g|svg)$/));
services.forEach((img,i)=>{
  let serv = document.querySelector(`.${fn(img)}`);
  if(serv){
    serv.src = img;
  }
});

/*ALLOCATE*/

// main logo
const websitelogoDark = document.querySelector('.logo.dark');
const websitelogoLight = document.querySelector('.logo.light');
if (websitelogoDark && websitelogoLight) {
  websitelogoDark.src = logo;
  websitelogoLight.src = logoWhite;
}

// team profiles
const pc = document.querySelectorAll(".profile-norm");
const pp = document.querySelectorAll(".profile-pop");
for(let p = 0; p < pc.length; p ++){
  if(pc[p] && pc[p]){
    if(p === 0){
      pc[p].src = 'assets/src/img/profile/jeff-comics.png';
      pp[p].src = 'assets/src/img/profile/jeff-pop.png';  
    }
    // if(p === 1){
    //   pc[p].src = 'assets/src/img/profile/wil-comics.png';
    //   pp[p].src = 'assets/src/img/profile/wil-pop.png';  
    // }
    if(p === 1){
      pc[p].src = 'assets/src/img/profile/joie-comics.png';
      pp[p].src = 'assets/src/img/profile/joie-pop.png';  
    }
  }
}

// select contact us
const slcPar = document.querySelector('.select');
const slc = document.querySelector('select[name="inquiry"]');
if(slc && slcPar){
  let inSelect = new select;
  inSelect.elem = slc;
  inSelect.parent = slcPar;
  inSelect.Select();
}

const websitelogowhite = document.querySelector('.logo-white');
if (websitelogowhite) {
  websitelogowhite.src = logoWhite;
}

const smcweb = document.querySelector(".smc-website");
if (smcweb) {
  smcweb.src = smcWebsite;
}

//email
let contactUs = document.querySelector("#contact-us");
if(contactUs){
  let eml = new email;
  eml.label = document.querySelector("#contact-us .email-notif");
  contactUs.addEventListener('submit',function(e) {
    e.preventDefault();
    eml.sendemail();
  });
}

let careerForm = document.querySelector('.careers-form');
if(careerForm){
  let crer = new email;
  let submit = document.querySelector('#careers');

  crer.form = careerForm;
  crer.formX = careerForm.querySelector('.back');
  crer.label = careerForm.querySelector('.form-label');
  crer.btn = document.querySelectorAll('.career');
  crer.showForm();

  submit.addEventListener('submit',function(e){
    e.preventDefault();
    crer.data = submit;
    crer.sendApplication();
  })
}

//MAIN MENU

//menu module
let nav = new navigation();
nav.btn = document.querySelector(".menu");
nav.menu = document.querySelector(".main-nav");

nav.menuItems = [
  {
    "title":"Home",
    "link":"/",
    "sublink":"",
    "_blank":false,
  },
  {
    "title":"About",
    "link":"about-us.html",
    "sublink":[
      {
        "title":"Our Process",
        "link":"about-us-process.html",
        "_blank":false,
      },
      {
        "title":"Our Team",
        "link":"about-us-teams.html",
        "_blank":false,
      }
    ]
  },
  {
    "title":"Services",
    "link":"services.html",
    "sublink":[
      // {
      //   "title":"Singapore",
      //   "link":"branches.html#singapore",
      //   "_blank":false,
      // },
      {
        "title":"Shanghai",
        "link":"branches.html#shanghai",
        "_blank":false,
      },
      {
        "title":"Manila",
        "link":"branches.html#manila",
        "_blank":false,
      }
    ]
  },
  {
    "title":"Works",
    "link":"works.html",
    "sublink":"",
    "_blank":false,
  },
  {
    "title":"Careers",
    "link":"careers.html",
    "sublink":"",
    "_blank":false,
  },
  {
    "title":"Contact Us",
    "link":"contact-us.html",
    "sublink":"",
    "_blank":false,
  },
];
nav.navInit();

// FOOTER
let footer = new navigation();
footer.menu = document.querySelector("footer");
footer.logo = logoWhite;
footer.locations = [
  {
    twinCol:true,
    title:`<strong><span>C3 Interactive</span><br/>Manila</strong>`,
    address:`<strong>Main Office</strong><br/>16/F Two World Square Mckinley Hill, Fort Bonifacio, Taguig City, Philippines.`,
  },
  {
    twinCol:true,
    title:`<strong><span>C3 Interactive</span><br/>Manila</strong>`,
    address:`<strong>Satellite Office</strong><br/>Unit 115 Avida SOHO Cityflex Towers. 7th Avenue cor. Lane T, North Bonifacio, Fort Bonifacio, Taguig City, Philippines.`,
  },
  {
    twinCol:false,
    title:`<strong><span>C3 Interactive</span><br/>Shanghai</strong>`,
    address:`Suite 801, 8/F Chuangzhan Building 928 Xi Kang Road 上海市西康路928号创展大厦 801`,
  },
  {
    twinCol:false,
    title:`<strong><span>C3 Interactive</span><br/>Singapore</strong>`,
    address:`8 Burn Road, #12-11 Trivex, Singapore 369977`,
  },
]

footer.menuItems = [
  {
    title:'Home',
    link:'/',
  },
  {
    title:'About',
    link:'/about-us.html',
  },
  {
    title:'Services',
    link:'/services.html',
  },
  {
    title:'Works',
    link:'/works.html',
  },
  {
    title:'Careers',
    link:'/careers.html',
  },

]

footer.copyRight = `Copyright C3 Interactive 2023, All Rights Reserved.`;
footer.footerInit();

// parallax module
const paraSect = document.querySelector(".three")
if(paraSect){
  let srcList = $('img').map(function() {return this.src;}).get();
  let cleanList = [];
  srcList.forEach((img,i)=>{
    if(img != '' && img != undefined){
      cleanList.push(img);
    }
  });
  let pl = new preloader;
  pl.preload(cleanList);
  console.log(pl)
  prllx();
}