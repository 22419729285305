import slick from "slick-carousel";
import $ from "jquery";

export default class Slider{
  imgs;
  type;

  slider(){

    let image = this.imgs;
    let slideType = this.type;
    let curPos = 0, totPos = 0;
    let images = [],items = [], itemOverlay = [], bubble = [];
    let slider = document.querySelector(`#${slideType} .slider`);
    let orient = "stop", centerThis = 0;
    let arrowLeft = document.querySelector(`.${slideType} .arrow-left`);
    let arrowRight = document.querySelector(`.${slideType} .arrow-right`);
    let sliderWidth, sliderPadding, imageWidth, spaceMove, current = 0, target = 0, ease = .02;
    let sliderInner = document.querySelector(`#${slideType} .slider-inner`);
    let curSlide = 0, ctr = 0;

    //for easing
    function lerp(start, end, t){
      return start * (1-t) + end * t;
    }

    function setTransform(elem, transform){
      elem.style.transform = transform;
    }

    function init(){
      // if(sliderInner && sliderInnerOverlay){
      if(sliderInner){
        
        sliderPadding = parseInt(window.getComputedStyle(sliderInner, null).getPropertyValue('padding-left'));
        if(window.innerWidth > 1024){
          sliderWidth = slider.getBoundingClientRect().width + (sliderPadding * 2);
          spaceMove = (sliderWidth / 8) - 500;
        }else{
          sliderWidth = sliderInner.getBoundingClientRect().width;
          spaceMove = (sliderWidth / 5) - (sliderWidth * .2);
        }
        
        imageWidth = sliderWidth / image.length;
        totPos = sliderWidth - (window.innerWidth + spaceMove);

        // setup slider
        sliderInner.innerHTML = '';
        image.forEach((p,i) => {
          // adding images
          itemOverlay[i] = document.createElement('a');
          itemOverlay[i].classList.add('item-overlay');
          itemOverlay[i].setAttribute('alt',image[i].name);
          itemOverlay[i].setAttribute('data-name',image[i].name);
          if(slideType == 'works'){
            images[i] = document.createElement('div');
            images[i].classList.add('img');
            images[i].style.backgroundImage = `url(${image[i].url})`;
          }else{
            itemOverlay[i].classList.add('small')
            images[i] = document.createElement('img');
            images[i].classList.add('img');
            images[i].src = image[i].url
          }
          itemOverlay[i].append(images[i]);
          if(image[i].link != ''){
            itemOverlay[i].setAttribute('href',image[i].link);
            itemOverlay[i].setAttribute('target','_blank');
          }else{
             itemOverlay[i].setAttribute('href',`#${slideType}`);
          }
          bubble[i] = document.createElement('span');
          bubble[i].classList.add('bubble');
          if(image[i].link != ''){
            bubble[i].innerHTML = `<em>Visit Site:</em>${image[i].name}`;
          }else{
            bubble[i].innerHTML = image[i].name;
          }
          sliderInner.append(itemOverlay[i]);

          // add events (bubble show upon hover)
          itemOverlay[i].addEventListener('mouseover',function(e){
            // trigger image on behind and move it to center
            images[i].classList.add("over");
            setTransform(images[i], `translateX(0)`);
            orient = "center";
            if(i < image.length - 1){
              const centerMe = itemOverlay[i].offsetLeft;
              centerThis = (centerMe - (window.innerWidth / 2)) + (this.getBoundingClientRect().width / 2);
            }else{
              centerThis = totPos + 20;
            }
          });
          itemOverlay[i].addEventListener('mousemove',function(e){
            // show bubble
            const trgt = e.target;
            const trgtRect = trgt.getBoundingClientRect();
            const x = e.clientX - trgtRect.left + 10;
            const y = (e.clientY - trgtRect.top) - bubble[i].offsetHeight - 10;

            bubble[i].style.left = `${x}px`;
            bubble[i].style.top = `${y}px`;
            bubble[i].classList.add("show");
          });
          bubble[i].addEventListener('mouseover',function(){
            images[i].classList.remove("over");
            bubble[i].classList.remove("show");
          });
          itemOverlay[i].addEventListener('mouseout',function(){
            images[i].classList.remove("over");
            bubble[i].classList.remove("show");
          });
        });

        // animate();

        // interactions
        // arrows

        // arrowLeft.addEventListener('click',function(){
        //   curSlideAct("prev-slide");
        // });

        // arrowRight.addEventListener('click',function(){
        //  curSlideAct("next-slide");
        // });
        // swipe left/right
        // slider.addEventListener('touchstart', handleTouchStart, false);        
        // slider.addEventListener('touchmove', handleTouchMove, false);
        // slider.addEventListener('touchend', handleTouchEnd, false);

        // var xDown = null;                                                        
        // var yDown = null;

        // function getTouches(evt) {
        //   return evt.touches || evt.originalEvent.touches;
        // } 

        // function handleTouchStart(evt) {
        //   const firstTouch = getTouches(evt)[0];                                      
        //   xDown = firstTouch.clientX;                                      
        //   yDown = firstTouch.clientY;                                      
        // };

        // function handleTouchMove(evt) {
        //   if (!xDown || !yDown ) {return;}

        //   var xUp = evt.touches[0].clientX;                                    
        //   var yUp = evt.touches[0].clientY;

        //   var xDiff = xDown - xUp;
        //   var yDiff = yDown - yUp;
                                                                               
        //   if ( Math.abs( xDiff ) > Math.abs( yDiff ) ) {
        //       if ( xDiff > 0 ) {
        //         curSlideAct("next-slide");
        //         // target = target < totPos ? target += 200 : totPos + 200;
        //       } else {
        //         curSlideAct("prev-slide");
        //         // target = target > 0 ? target -= 200 : 0;
        //       }
        //       // current = parseFloat(lerp(current, target, ease)).toFixed(2);
        //       // setTransform(slider,`translateX(-${current}px)`);
        //   }
        //   /* reset values */
        //   xDown = null;
        //   yDown = null;                                             
        // };

        // function handleTouchEnd(evt){
        //   curSlideAct("stop");
        // }
      }
    }
    init();
    insertSlick();

    function insertSlick(){
      if(slideType == 'works'){
        $(sliderInner).slick({
          infinite: true,
          variableWidth:true,
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode:true,
          centerPadding:0,
          dots: false,
          prevArrow: $('#works .arrow-left'),
          nextArrow: $('#works .arrow-right'),
          // responsive: [
          //   {
          //     breakpoint: 768,
          //     settings: {
          //       centerMode:false,
          //     }
          //   },
          // ],
        });
      }else{
        $(sliderInner).slick({
          infinite: true,
          variableWidth:true,
          centerMode:false,
          dots: false,
          slidesToShow: 3,
          slidesToScroll: 3,
          prevArrow: $('#clients .arrow-left'),
          nextArrow: $('#clients .arrow-right'),
          // responsive: [
          //   {
          //     breakpoint: 768,
          //     settings: {
          //       slidesToShow: 1,
          //       slidesToScroll: 1,
          //     }
          //   },
          // ],
        });
      }
    }

    function curSlideAct(orient){
      const d = (itemOverlay.length - 1) % 3;
      const c = parseInt((itemOverlay.length - 1) / 3);

      if(orient == "next-slide"){
        if(itemOverlay[curSlide].classList.contains('small')){
          //check if slide divisible by 3
          
          if(d !== 0 && (c === ctr)){
            curSlide = curSlide < itemOverlay.length - 1 ? curSlide + d : itemOverlay.length - 1;
            ctr = c;
          }else{
            curSlide = curSlide < itemOverlay.length - 1 ? curSlide + 3 : itemOverlay.length - 1;
            ctr++;
          }
        }else{
          curSlide = curSlide < itemOverlay.length - 1 ? curSlide + 1 : itemOverlay.length - 1;
        }

        target = itemOverlay[curSlide].offsetLeft - ((window.innerWidth / 2) - (itemOverlay[curSlide].offsetWidth / 2));
        if(curSlide === itemOverlay.length - 1){
          arrowRight.classList.remove('show');
        }
        arrowLeft.classList.add('show');
      }else if(orient == "prev-slide"){
        if(itemOverlay[curSlide].classList.contains('small')){
          if(d !== 0 && (ctr === c)){
            curSlide = curSlide > 0 ? curSlide - d : 0;
          }else{
            curSlide = curSlide > 0 ? curSlide - 3 : 0;
            
          }
          ctr = ctr > 0 ? ctr - 1  : 0;
        }else{
          curSlide = curSlide > 0 ? curSlide - 1 : 0;
        }
        
        target = itemOverlay[curSlide].offsetLeft - ((window.innerWidth / 2) - (itemOverlay[curSlide].offsetWidth / 2));
        if(curSlide === 0){
          arrowLeft.classList.remove('show');
        }
        arrowRight.classList.add('show');
      // }else if(orient == "center"){
        // target = centerThis;
      }else{
        target = target;
        console.log('stop');
      }
    }

    function animate(){
      current = parseFloat(lerp(current, target, ease)).toFixed(2);
      setTransform(slider,`translateX(-${current}px)`);
      if(slideType == 'works'){ 
        animateImages();
      }
      requestAnimationFrame(animate);
    }

    function animateImages(){
      let ratio = current / imageWidth;
      let intersectionRatioValue;

      images.forEach((image, idx) =>{
        intersectionRatioValue = ratio - (idx * .7);
        let easeX = intersectionRatioValue * 100;
        if(easeX > 100 && window.innerWidth > 1024){
          easeX = 100
        }if(easeX < -100 && window.innerWidth > 1024){
          easeX = -100
        }if(easeX > 30 && window.innerWidth < 1024){
          easeX = 30
        }if(easeX < -30 && window.innerWidth < 1024){
          easeX = -30
        }
        if(!image.classList.contains("over")){
          setTransform(image, `translateX(${easeX}px)`);  
        }
      });
    }

    window.addEventListener('resize',init);
  }
}