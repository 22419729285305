import * as THREE from 'three';
import $ from 'jquery';
import gsap from "gsap";
import * as jqueryUI from './jquery-ui.min.js';
import { LineMaterial } from 'three/addons/lines/LineMaterial.js';
import { Wireframe } from 'three/addons/lines/Wireframe.js';
import { WireframeGeometry2 } from 'three/addons/lines/WireframeGeometry2.js';
import { ConvexGeometry } from 'three/addons/geometries/ConvexGeometry.js';
import * as BufferGeometryUtils from 'three/addons/utils/BufferGeometryUtils.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

//  import icons
import yt from './img/icons/yt-icon.png';
import tw from './img/icons/tw-icon.png';
import fb from './img/icons/fb-icon.png';
import ig from './img/icons/ig-icon.png';
import app from './img/icons/app-icon.png';
import edm from './img/icons/edm-icon.png';
import mail from './img/icons/mail-icon.png';
import sem from './img/icons/sem-icon.png';
import seo from './img/icons/seo-icon.png';
import smm from './img/icons/smm-icon.png';
import web from './img/icons/web-icon.png';
import prof from './img/icons/prof-icon.png';
import clk from './img/icons/click-icon.png';
import blk from './img/icons/blank.png';
import sqr from './img/icons/sqr.png';

// import models
import mobile from './models/mobile/mobile.glb';
import laptop from './models/laptop/laptop.glb';
import monitor from './models/monitor/monitor.glb';
import coffee from './models/coffee/coffee.glb';
import tag from './models/tag/tag.glb';


function parallax(){
	let reso = document.querySelector(".reso");
	let showReso = false;
	if(reso && showReso){
		reso.innerHTML = `${window.innerWidth}px | ${window.innerHeight}px`;
	}
	
	let scrollArr = window.innerWidth > 768 ? [0,3,13,17] : [0,3,13,17];
	let activeContent = ['banner','who','approach','purpose','teams','branches','clients','works','contact','footer'];
	let curSlide = 0, anim = true;
	let wireframe, wireframe1, renderer, rendererArr = [], scene, sceneArr = [], camera, parameters;
	let matLine, matLineBasic, matLineDashed, clock;
	let particles = [],modelArr = [], sprites = [], group = [], crossGrp = [], grp;

	let windowHalfX = window.innerWidth / 2;
	let windowHalfY = window.innerHeight / 2;
	let insetWidth;
	let insetHeight;
	let body = document.querySelector("body");
	let oldx = 0, oldy = 0, chx, chy;

	let aside = document.querySelectorAll(".scroll-indicator-container li a");
	let section = document.querySelector(".three.desk");
	let sectionMobile = document.querySelectorAll(".three.mobile");
	
	// model preloader
	let modelLoader = new THREE.LoadingManager();
	modelLoader.onStart = function ( model, itemsLoaded, itemsTotal ) {
		console.log( 'Start: Loaded ' + itemsLoaded + ' of ' + itemsTotal + ' files.' );
	};

	modelLoader.onLoad = function () {
		console.log( 'Loading Success');
		if(window.innerWidth > 768){
			$('html,body').animate({scrollTop:1000},0,'easeOutQuad');
			$('html,body').stop().delay(2000).animate({scrollTop:0},2000,'easeOutQuad');
		}else{
			$('html,body').stop().animate({scrollTop:0},100,'easeOutQuad');
		}
		$('.preloader').stop().delay(1000).animate({top:'-100vh',opacity:0},2000,'');
		$(".percentage").stop().delay(500).animate({opacity:0},1000,'easeOutQuad');
		animate();
	};

	modelLoader.onProgress = function ( model, itemsLoaded, itemsTotal ) {
		console.log( 'Loaded ' + itemsLoaded + ' of ' + itemsTotal + ' files.' );
		const percent = parseInt(((itemsLoaded / itemsTotal) * 100)/2);
		let per = document.querySelector(".percentage span");

		// if(percent < 10 ){
		// 	per.innerHTML = `0${percent}`;
		// }else{
		// 	per.innerHTML = percent;
		// }
		per.innerHTML = parseInt(50 + percent);
	};

	modelLoader.onError = function ( model ) {
		console.log( 'There was an error loading');
	};

	// init function
	function initTHREE(){
		if(!section){
			return false;
		}

		activeContent.forEach(function(elem,i){
			const getElem = document.querySelector(`.${elem}`);
			const parentMain = getElem.parentElement.classList[0];
			const priHeight = $('.primary').height();
			const marTopContent = getElem.firstElementChild;
			let marTop = marTopContent.currentStyle || window.getComputedStyle(marTopContent);
			let intMarTop = parseInt(marTop.marginTop,10) != NaN ? parseInt(marTop.marginTop,10) : 0; 
			let elemST;
			if(parentMain == "secondary" || getElem.classList[0] == "footer"){
				if(parentMain == "secondary"){
					elemST = getElem.offsetTop + priHeight + intMarTop;
				}else{
					elemST = getElem.offsetTop
				}
				const multiplier = parseFloat(elemST / window.innerHeight).toFixed(2);
				scrollArr.push(multiplier);
			}
		});

		camera = new THREE.PerspectiveCamera( 75, window.innerWidth / window.innerHeight, 0.1, 1000 );
		clock = new THREE.Clock();

		if(window.innerWidth > 768){
			renderer = new THREE.WebGLRenderer({alpha:true,antialias:1});
			renderer.setSize( window.innerWidth, window.innerHeight);

			scene = new THREE.Scene();
			section.appendChild( renderer.domElement );

			// const light = new THREE.HemisphereLight( 0xaaaaaa, 0xBFD0DD, 1 );
			const light = new THREE.HemisphereLight( 0xffffff, 0x333e48, 1 )
			scene.add( light );

			const width = 10;
			const height = 10;
			const intensity = 1;
			// const rectLight = new THREE.RectAreaLight( 0x333E48, intensity,  width, height );
			const rectLight = new THREE.RectAreaLight( 0xeeeeee, intensity,  width, height );
			rectLight.position.set( 5, 0, 0 );
			rectLight.lookAt( 0, 0, 0 );
			scene.add( rectLight )

			// const rectLight1 = new THREE.RectAreaLight( 0x96C940, intensity,  width, height );
			const rectLight1 = new THREE.RectAreaLight( 0xffffff, intensity,  width, height );
			rectLight1.position.set( 0, 5, 0 );
			rectLight1.lookAt( 0, 0, 0 );
			scene.add( rectLight1 );
		}else{
			sectionMobile.forEach(function(elem,i){
				rendererArr[i] = new THREE.WebGLRenderer({alpha:true,antialias:1});
				rendererArr[i].setSize( window.innerWidth, window.innerHeight);

				sceneArr[i] = new THREE.Scene();
				sectionMobile[i].appendChild(rendererArr[i].domElement);

				// const light = new THREE.HemisphereLight( 0xaaaaaa, 0xBFD0DD, 1 );
				const light = new THREE.HemisphereLight( 0xffffff, 0x333e48, 1 )
				sceneArr[i].add( light );

				const width = 10;
				const height = 10;
				const intensity = 1;
				// const rectLight = new THREE.RectAreaLight( 0x333E48, intensity,  width, height );
				const rectLight = new THREE.RectAreaLight( 0xeeeeee, intensity,  width, height );
				rectLight.position.set( 5, 0, 0 );
				rectLight.lookAt( 0, 0, 0 );
				sceneArr[i].add( rectLight )

				// const rectLight1 = new THREE.RectAreaLight( 0x96C940, intensity,  width, height );
				const rectLight1 = new THREE.RectAreaLight( 0xffffff, intensity,  width, height );
				rectLight1.position.set( 0, 5, 0 );
				rectLight1.lookAt( 0, 0, 0 );
				sceneArr[i].add( rectLight1 );
			});
		}

		camera.position.z = 6;

		//aside nav functions
		aside.forEach((nav,i)=>{
			nav.addEventListener('click',function(e){
				e.preventDefault();
				let layer = document.querySelector(".active");
			
				const l1 = nav.getAttribute("href");
				const l2 = l1.slice(1,l1.length);
				let curLayer = document.querySelector(`.${l2}`);
				if(i < curSlide){
					layer.classList.remove('active');
					curLayer.classList.add('active');
				}
				curSlide = i;
				scrollAnim(curSlide);
			});
		});

		//cast models here
		// DESKTOP
		if(window.innerWidth > 768){
			// flying upward
			models({
				index:0,
				name:'mobile-aw',
				posY:-30,
				posX: -1,
				scale: .5,
			});

			// mobile-2
			models({
				index:1,
				name:'mobile-aw',
				posY:-30,
				posX: 3,
				scale: .8,
			});

			// mobile-3
			models({
				index:2,
				name:'mobile-aw',
				posY:-30,
				scale: 1,
			});

			// laptop-1
			models({
				index:3,
				name:'laptop-aw',
				posY:-30,
				posX:-3,
				scale:.3
			});

			// laptop-2
			models({
				index:4,
				name:'laptop-aw',
				posY:-30,
				posX:3,
				scale:.5
			});

			// laptop-3
			models({
				index:5,
				name:'laptop-aw',
				posY:-30,
				scale:.5
			});

			// monitor
			models({
				index:8,
				name:'monitor-aw',
				posY:-22,
				posZ:-6,
				rotx:1,
				scale:1.1
			});

			// Approach - floating gadgets
			// mobile 
			models({
				index:9,
				name:'mobile-aw',
				posZ:0,
				posX:0,
				scale:.7,
				wl:true,
				wls:.2,
				wlo:1
			});

			// laptop
			models({
				index:10,
				name:'laptop-aw',
				posZ:0,
				posX:-7,
				scale:.2,
				wl:true,
				wls:.2,
				wlo:1
			});

			// monitor
			models({
				index:11,
				name:'monitor-aw',
				posZ:0,
				posX:7,
				scale:.25,
				wl:true,
				wls:.2,
				wlo:1
			});

			// 3rd Panel flying sideward
			// mobile-3
			models({
				index:12,
				name:'mobile-aw',
				rotz: -5,
				scale: .5,
			});

			models({
				index:13,
				name:'mobile-aw',
				rotz: -5,
				scale: .7,
			});

			models({
				index:14,
				name:'mobile-aw',
				rotz: -5,
				scale: 1,
			});


			models({
				index:15,
				name:'mobile-aw',
				rotz: -5,
				scale: 1.1,
			});


			models({
				index:16,
				name:'mobile-aw',
				rotz: -5,
				scale: 1.5
			});

			models({
				index:17,
				name:'laptop-aw',
				rotz: -5,
				scale: .2,
			});

			models({
				index:18,
				name:'laptop-aw',
				rotz: -5,
				scale: .3,
			});

			models({
				index:19,
				name:'laptop-aw',
				rotz: -5,
				scale: .4,
			});

			models({
				index:20,
				name:'laptop-aw',
				rotz: -5,
				scale: .8,
			});

			models({
				index:21,
				name:'laptop-aw',
				rotz: -5,
				scale: 1,
			});

			models({
				index:22,
				name:'monitor-aw',
				rotz: -5,
				scale: 1.2,
			});

			// Coffee - Our Purpose
			models({
				index:23,
				name:'coffee-aw',
				rotz: .5,
				scale: .5,
			});

			// Who
			models({
				index:24,
				name:'none',
				wl:true,
				wls:.5,
				wlr:false,
				wlo:1,
			});
		}
		// MOBILE
		else{
			// Who BG
			models({
				index:0,
				name:'none',
				wl:true,
				wls:.25,
				wlr:false,
				wlo:.1,
				canvasParent:0,
			});

			// floating mobile
			models({
				index:1,
				name:'mobile-aw',
				posY:-2,
				scale:.7,
				wl:true,
				wls:.2,
				wlo:1,
				canvasParent:1,
			});

			// // floating coffee
			models({
				index:2,
				name:'coffee-aw',
				posY:-3,
				rotz: .5,
				scale: .5,
				canvasParent:2,
			});
		}
		
		// line dashing
		// line one
		// createLine({
		// 	grp:0,
		// 	coor:[
		// 		["0,4,0","5,3.8,0"],
		// 		["0,3,0","5,2.8,0"],
		// 	],
		// 	clr: 0x96C93D
		// });
		if(window.innerWidth > 768){
			renderer.render( scene, camera );
		}else{
			sceneArr.forEach(function(elem,i){
				rendererArr[i].render( sceneArr[i], camera );
			})
		}
		
	}


	window.addEventListener("wheel", function(e){
		if(anim && window.innerWidth > 768){
			move(e.deltaY);
		}
	},false);

	document.addEventListener('touchstart', handleTouchStart, false);        
	document.addEventListener('touchmove', handleTouchMove, false);

	let xDown = null;                                                        
	let yDown = null;

	function getTouches(evt) {
 		return evt.touches || evt.originalEvent.touches;
	}

	function handleTouchStart(evt) {
		if(anim){
			const firstTouch = getTouches(evt)[0];                                      
			xDown = firstTouch.clientX;                                      
			yDown = firstTouch.clientY;      
		}
                                
	};

	function handleTouchMove(evt) {
		if(anim && window.innerWidth > 768 && window.innerWidth < 1200){
	    if(! xDown || ! yDown ){
	        return;
	    }

	    var xUp = evt.touches[0].clientX;                                    
	    var yUp = evt.touches[0].clientY;

	    var xDiff = xDown - xUp;
	    var yDiff = yDown - yUp;
	                             
			if ( Math.abs( xDiff ) > Math.abs( yDiff ) ) {/*most significant*/
        if ( xDiff > 0 ) {
          move(0);
        } else {
          move(0);
        }                       
	    } else {
	      if( yDiff > 0 ) {
	      	move(1);
		    }else{ 
		      move(-1);
		    }                                                                  
	    }                                           
	                                                                    
	    /* reset values */
	    xDown = null;
	    yDown = null;       
    }                                      
	};      

	function castParallax() {
		window.addEventListener("scroll", function(event){
			let top = this.pageYOffset;

			let layers = document.querySelectorAll(".active .parallax");
			let activeSect = $(".active"); 
			let layer, speed, orient, yPos, effect, zindex;
			
			// get entire height
			let hE = document.documentElement;
			let bE = document.body;

			// get Total height of the doc
			let totHeight = Math.max(hE.clientHeight, hE.scrollHeight, hE.offsetHeight, bE.scrollHeight, bE.offsetHeight);

			for (let i = 0; i < layers.length; i++) {
				layer = layers[i];
				if(window.innerWidth < 768){
					if(layer.closest('section').classList.contains('works')){
						return false;
					}
				}
				speed = layer.getAttribute('data-speed');
				orient = layer.getAttribute('data-orientation');
				effect = layer.getAttribute('data-effects');
				zindex = layer.getAttribute('data-layer') != null ? layer.getAttribute('data-layer') : 0;

				//get orientation (-)top, (+)bottom.
				let atm = layer.currentStyle || window.getComputedStyle(layer);
				let at = activeSect.position().top + parseInt(atm.marginTop);
				yPos = orient == "top" ? -1 * ((top - at) * speed / 100) : 1 * ((top - at) * speed / 100);

				if(top > window.innerHeight){
					body.classList.add("show");
				}else{
					body.classList.remove("show");
				}
				
				//get effects
				if(effect != null){
					let effProp = effect.split(" ");
					effProp === 0 ? 1 : effProp.length;
					for(let e = 0; e < effProp.length; e++){
						let effSet = effProp[e].split("-");
						for(let es = 0; es < effSet.length; es++){
							//get percentage trigger effect
							let triggerPer = parseInt(effSet[3])/100;
							let endPer = parseInt(effSet[4])/100;

							let triggerStart = orient == "top" ? (totHeight * triggerPer) * -1 : (totHeight * triggerPer);
							let triggerEnd = orient == "top" ? (totHeight * endPer) * -1 : (totHeight * endPer);
							if(effSet[0] == "alpha"){
								//get alpha from/to
								let eFrom = parseInt(effSet[1])/100;
								let eTo = parseInt(effSet[2])/100;
								
								//1st percent trigger, 2nd percent effect fulfilled
								let opa	= eFrom// - (1 - (eTo * (yPos/triggerEnd)));
								let ti = Math.round((yPos - triggerStart) / (triggerEnd - triggerStart)*100)/100;

								let per = 0;
								if(ti < 0){
									per = 0;
								}else if(ti > 1.00){
									per = 1.00;
								}else{
									per = ti;
								}

								if(yPos > triggerStart && yPos < triggerEnd){
									if(eFrom > eTo){
										opa = eFrom - ((1 - eTo) * ti);
									}else{
										opa = eTo * ti;
									}
								}if(yPos > triggerEnd){
									opa = eTo;
								}
								layer.style.opacity = opa;
								layer.style.transform = `translate3d(0px, ${yPos}px, 0px)`;
								layer.style.zindex = zindex;
								//layer.setAttribute('style',`opacity: ${opa};transform: `);
							}
						}
					}
				}else{
					layer.style.transform = `translate3d(0px, ${yPos}px, 0px)`
					layer.style.zindex = zindex;
					//layer.setAttribute('style',`transform: translate3d(0px, ${yPos}px, 0px); z-index: ${zindex}`);
				}
			}
		});
	}
	
	// set active state and scroll anim
	function move(yaxis,event){
		if(yaxis < 0){
			curSlide--;
			if(curSlide <= 0){curSlide = 0}
			for(let a = 0; a < activeContent.length; a++){
				if(document.querySelector(`.${activeContent[a]}`) ){
					document.querySelector(`.${activeContent[a]}`).classList.remove("active");
				}
			}
			if(document.querySelector(`.${activeContent[curSlide]}`)){
				document.querySelector(`.${activeContent[curSlide]}`).classList.add("active");
		}
		}else if(curSlide === (activeContent.length - 1)){
			curSlide = (activeContent.length - 1);
		}else if(yaxis > 0){
			curSlide++;
			if(curSlide >= scrollArr[scrollArr.length-1]){curSlide = scrollArr.length-1;}
		}	
		anim = false;
		scrollAnim(curSlide);
	}

	function scrollAnim(slide){
		// if(window.innerWidth > 768){
			let sh = window.innerHeight * scrollArr[slide];
			
			if(slide == 1 || (slide > 3)){
				$("header").addClass('dark')
				$(".scroll-indicator-container,.menu").addClass("dark");
			}else{
				$("header").removeClass('dark')
				$(".scroll-indicator-container,.menu").removeClass("dark");
			}
			$('html,body').animate({scrollTop:sh},3000,'easeOutQuad',function(){
				anim = true;
				for(let a = 0; a < activeContent.length; a++){
					if(document.querySelector(`.${activeContent[a]}`) ){
						document.querySelector(`.${activeContent[a]}`).classList.remove("active");
					}	
				}
				if(document.querySelector(`.${activeContent[slide]}`)){
					document.querySelector(`.${activeContent[slide]}`).classList.add("active");
					// set active aside btns
					aside.forEach((nav,i)=>{
						const l1 = nav.getAttribute("href");
						const l2 = l1.slice(1,l1.length);
						if(l2 == activeContent[slide]){
							nav.classList.add("current");
						}else{
							nav.classList.remove("current")
						}
					});
				}
			});
		// }
	}

	function models({index,name,posX,posY,posZ,rotx,roty,rotz,scale,wl,wls,wlr,wlo,canvasParent}){
		const gltfLoader = new GLTFLoader(modelLoader);
		let loaded = false;
		let pathGLB,
		ms = scale != undefined ? scale : 1.5;

		switch(name){
			case 'mobile-aw':
				pathGLB = mobile;
			break;
			case 'laptop-aw':
				pathGLB = laptop;
			break;
			case 'monitor-aw':
				pathGLB = monitor;
			break;
			case 'coffee-aw':
				pathGLB = coffee;
			break;
			case 'tag':
				pathGLB = tag;
			break;
			case 'none':
				pathGLB = tag;
			break;
		}

		const withLinks = wl ? wl : false;
		const wli = index;
		const wlx = posX ? posX : 0;
		const wly = posY ? posY : 0;
		const wlz = posZ ? posZ : 0;

		gltfLoader.load(pathGLB, (gltfScene) =>{
			// gltfScene.scene.rotation.y = Math.PI / 2; 
			gltfScene.scene.position.x = posX != undefined ? posX : 0;
		  gltfScene.scene.position.y = posY != undefined ? posY : 0;
		  gltfScene.scene.position.z = posZ != undefined ? posZ : 0;
		  gltfScene.scene.rotation.x = rotx != undefined ? rotx : 0;
		  gltfScene.scene.rotation.y = roty != undefined ? roty : 0;
		  gltfScene.scene.rotation.z = rotz != undefined ? rotz : 0;
		  gltfScene.scene.scale.set(ms, ms, ms);
		  gltfScene.material = new THREE.MeshToonMaterial(0x96C940);
		  if(name === 'none'){
		  	gltfScene.scene.visible = false
		  }
		  if(window.innerWidth > 768){
		  	scene.add(gltfScene.scene);
		  }else{
		  	sceneArr[canvasParent].add(gltfScene.scene);
		  }
		  
		  modelArr[index] = gltfScene.scene;
		});

		if(wl){
			addDMLinks({
				index:wli,
				posX:wlx,
				posY:wly,
				posZ:wlz,
				scale:wls,
				rand:wlr != undefined ? false : true,
				opacity:wlo,
				canvasParent:canvasParent, 
			});
		}
	}

	function createLine({coor=[],grp,clr}){
		const material = new THREE.LineBasicMaterial( { color: clr  } );
		crossGrp[grp] = new THREE.Group();
		scene.add(crossGrp[grp]);
		
		for(let c = 0; c < coor.length; c++){
			let geometry, line, points = [];
			for(let l = 0; l < coor[c].length; l++){
				let crd = coor[c][l].split(",");
				points.push( new THREE.Vector3( crd[0], crd[1], crd[2] ) );
				geometry = new THREE.BufferGeometry().setFromPoints( points );
				line = new THREE.Line( geometry, material );
				crossGrp[grp].add(line);
			}
		}
	}

	function addDMLinks({index,posX,posY,posZ,scale,rand,opacity,canvasParent}){
		group[index] = new THREE.Group();
		// scene.add( group[index] );
		if(window.innerWidth > 768){
	  	scene.add( group[index]);
	  }else{
	  	sceneArr[canvasParent].add( group[index]);
	  }

		let geo = new THREE.DodecahedronGeometry( 10);

		geo.deleteAttribute( 'normal' );
		geo.deleteAttribute( 'uv' );

		geo = BufferGeometryUtils.mergeVertices( geo );

		const geometry = new WireframeGeometry2( geo );

		matLine = new LineMaterial( {
			color: 0xededed,
			linewidth: 1,
			dashed: false,
			transparent: true,
			opacity: opacity,
		} );

		wireframe = new Wireframe( geometry, matLine );
		wireframe.computeLineDistances();
		wireframe.scale.set( 1, 1, 1 );
		wireframe.visible = true;
		group[index].add( wireframe );

		geo = new THREE.WireframeGeometry( geo );

		let vertices = [];
		const positionAttribute = geo.getAttribute( 'position' );

		for ( let i = 0; i < positionAttribute.count; i ++ ) {
			const vertex = new THREE.Vector3();
			vertex.fromBufferAttribute( positionAttribute, i );
			vertices.push( vertex );
		}

		const textureLoader = new THREE.TextureLoader(modelLoader);
		sprites[0] = textureLoader.load( blk );
		sprites[1] = textureLoader.load( yt );
		sprites[2] = textureLoader.load( tw );
		sprites[3] = textureLoader.load( fb );
		sprites[4] = textureLoader.load( ig );
		sprites[5] = textureLoader.load( app );
		sprites[6] = textureLoader.load( edm );
		sprites[7] = textureLoader.load( mail );
		sprites[8] = textureLoader.load( sem );
		sprites[9] = textureLoader.load( seo );
		sprites[10] = textureLoader.load( smm );
		sprites[11] = textureLoader.load( web );
		sprites[12] = textureLoader.load( prof );
		sprites[13] = textureLoader.load( clk );
		sprites[14] = textureLoader.load( sqr );

		for ( let i = 0; i < positionAttribute.count; i ++ ) {
		// for ( let i = 0; i < 8; i ++ ) {

			let sprite,size;
			const ctr =  Math.floor((Math.random() * 13) + 1);
			if(rand){
				sprite = i % 12 == 0 ? sprites[ctr] : sprites[0];
				size = (Math.random() * .5) + .1;
			}else{
				sprite = sprites[14];
				size = .3
			}
			const pointsGeometry = new THREE.BufferGeometry().setAttribute( 'position', new THREE.Float32BufferAttribute( vertices[i], 3 ) );

			const materials = new THREE.PointsMaterial( { size: size, map: sprite, blending: THREE.NormalBlending, depthTest: true, depthWrite: false, transparent: true, opacity: opacity} );

			particles[i] = new THREE.Points( pointsGeometry, materials );

			group[index].add( particles[i] );

		}

		matLineBasic = new THREE.LineBasicMaterial( { color: 0xEEEEEE, transparent: true, opacity: opacity} );

		wireframe1 = new THREE.LineSegments( geo, matLineBasic );
		wireframe1.computeLineDistances();
		wireframe1.visible = false;
		group[index].add( wireframe1 );

		matLine.resolution.set( window.innerWidth, window.innerHeight );

		const ms = scale != undefined ? scale : .5;
		group[index].position.x = posX != undefined ? posX : 0;
	  group[index].position.y = posY != undefined ? posY : 0;
	  group[index].position.z = posZ != undefined ? posZ : 0;
		group[index].scale.set(ms,ms,ms);
	}

	var mouse = new THREE.Vector2();
				
	let plane = new THREE.Plane(new THREE.Vector3(0, 0, 1), -1);
	let raycaster = new THREE.Raycaster();
	let pointOfIntersection = new THREE.Vector3();

	let target = new THREE.Vector3(); 
	var mouseX = 0, mouseY = 0;

	function animate() {
		// get overlay controls
		let whoLink = document.querySelector(".who.link");
		let approachLink = document.querySelector(".approach.link");
		let purposeLink = document.querySelector(".purpose.link");
		let teamPics = document.querySelector(".team-pics");
		let branchesLink = document.querySelector(".branches.link");
		// let clientControl = document.querySelector(".slider-control.clients");
		// let workControl = document.querySelector(".slider-control.works");

		requestAnimationFrame( animate );
		// DESKTOP
		const time = clock.getElapsedTime();
		if(window.innerWidth > 768){
			// for 3D models only
			const c = (window.pageYOffset - window.innerHeight) / ((window.innerHeight * 9) - window.innerHeight);
			const psY = c > 0 ? c * 30 : 0;
			const ct = (window.pageYOffset - (window.innerHeight * 3)) / ((window.innerHeight * 9) - (window.innerHeight * 3));
			const posY = ct > 0 ? ct * 30 : 0;

			// for overlay links/controls
			const ocT = (window.pageYOffset - window.innerHeight) / ((window.innerHeight * 9) - window.innerHeight);
			const posYoc = ocT > 0 ? (ocT * 100) : 0;

			//1st to 2nd slide transition
			// const g1s = (posYoc - 15)/10;
	  	// const g1t = g1s > 0 ? g1s : 0;
			// modelArr[24].position.y = (-15 + (15 * g1t)) + (Math.sin(time) * .3);
			// group[24].position.y = (-15 + (15 * g1t)) + (Math.sin(time) * .3);
			modelArr[24].position.y = -8 + psY;
			group[24].position.y = -8 + psY;

	  	modelArr[24].rotation.z += .001;
	  	modelArr[24].rotation.y = Math.sin( time ) * .3;;
			group[24].rotation.y += .005;

			modelArr[0].position.y = -10 + posY;
			modelArr[0].rotation.x = ct * 0.1 - -.5;
			modelArr[0].rotation.y = ct * 1 - -.5;

			modelArr[1].position.y = -13 + posY;
			modelArr[1].rotation.x = ct * 0.1 - -.5;
			modelArr[1].rotation.y = ct * -1 - .5;

			modelArr[2].position.y = -18 + posY;
			modelArr[2].rotation.x = ct * .2 - -.4;

			modelArr[3].position.y = -23 + posY;
			modelArr[3].rotation.x = ct * 0.5 - -.1;
			modelArr[3].rotation.y = ct * 1.2 - .5;
			modelArr[3].rotation.z = ct * -0.5 - .3;

			modelArr[4].position.y = -25 + posY;
			modelArr[4].rotation.x = ct * 0.5 - .5;
			modelArr[4].rotation.y = ct * -1.3 - .8;
			modelArr[4].rotation.z = ct * -.5 - -.3;

			modelArr[5].position.y = -32 + posY;
			modelArr[5].rotation.x = ct * 1 - .5;
			modelArr[5].rotation.y = ct * -.5 + .8;
			// modelArr[4].rotation.z = ct * -.5 - -.3;

			modelArr[8].position.y = -40 + posY;

			// 2nd slide animations
			target.x += ( mouse.x - target.x  ) * .002;
	  	target.y += ( mouse.y - target.y ) * .002;
	  	target.z = (Math.cos( time ) * 1) + 2 ;


			modelArr[9].position.y = -53 + posY + (Math.sin( time ) * .3);
			group[9].position.y = -53 + posY + (Math.sin( time ) * .3);
			modelArr[9].lookAt(target.x, target.y, target.z);
			
	  	modelArr[10].position.y = -48.5 + posY + (Math.sin( time ) * .3);
	  	group[10].position.y = -48.5 + posY + (Math.sin( time ) * .3);
	  	modelArr[10].lookAt(target.x, (target.x - 2), target.z);

	  	modelArr[11].position.y = -49 + posY + (Math.sin( time ) * .3);
	  	group[11].position.y = -47.8 + posY + (Math.sin( time ) * .3);
	  	modelArr[11].lookAt((target.x - 1), (target.x - 1), target.z);

	  	for(let i = 9; i <= 11; i++){
	  		group[i].rotation.x = (Math.cos( time ) * .3);
				group[i].rotation.y += .001;
				group[i].rotation.z = (Math.sin( time ) * .3);
	  	}

	  	// 3rd slide
	  	const ct2 = (window.pageYOffset - (window.innerHeight * 14)) / ((window.innerHeight * 17) - (window.innerHeight * 14));
			const cr = ct2 >= 0 && ct2 < 1 ? ct2 : 1;

			// flying gadgets
	  	modelArr[12].position.y = -1 + 5 * cr;
	  	modelArr[12].position.x = 10 - 80 * cr;
	  	modelArr[12].position.z = 1 - 8 * cr;
	  	modelArr[12].rotation.y = -.2 + .1 * cr;
	  	modelArr[12].rotation.x = -.2 + .1 * cr;

	  	modelArr[13].position.y = -2 + 7 * cr;
	  	modelArr[13].position.x = 20 - 80 * cr;
	  	modelArr[13].position.z = 1.5 - 8.5 * cr;
	  	modelArr[13].rotation.y = -.2 + .2 * cr;
	  	modelArr[13].rotation.x = -.2 + .2 * cr;

	  	modelArr[14].position.y = -5 + 3 * cr;
	  	modelArr[14].position.x = 25 - 80 * cr;
	  	modelArr[14].position.z = 2 - 10 * cr;
	  	modelArr[14].rotation.y = -.3 + .3 * cr;
	  	modelArr[14].rotation.x = -.3 + .3 * cr;

	  	modelArr[15].position.y = -3 + 7 * cr;
	  	modelArr[15].position.x = 40 - 90 * cr;
	  	modelArr[15].position.z = 2.5 - 12 * cr;
	  	modelArr[15].rotation.y = -.5 + .5 * cr;
	  	modelArr[15].rotation.x = -.5 + .5 * cr;

	  	modelArr[16].position.y = -7 + 8 * cr;
	  	modelArr[16].position.x = 60 - 90 * cr;
	  	modelArr[16].position.z = 5 - 12 * cr;
	  	modelArr[16].rotation.y = -.6 + .6 * cr;
	  	modelArr[16].rotation.x = -.6 + .6 * cr;

			modelArr[17].position.y = -7 + 10 * cr;
	  	modelArr[17].position.x = 22 - 82 * cr;
	  	modelArr[17].position.z = 1.5 - 8.5 * cr;
	  	modelArr[17].rotation.y = .8 - .2 * cr;

	  	modelArr[18].position.y = -2 + 10 * cr;
	  	modelArr[18].position.x = 31 - 85 * cr;
	  	modelArr[18].position.z = .5 - .5 * cr;
	  	modelArr[18].rotation.y = .8 - .2 * cr;

	  	modelArr[19].position.y = -1 + 1 * cr;
	  	modelArr[19].position.x = 43 - 90 * cr;
	  	modelArr[19].position.z = .7 - .7 * cr;
	  	modelArr[19].rotation.y = .8 - .2 * cr;

	  	modelArr[20].position.y = -3 + 5 * cr;
	  	modelArr[20].position.x = 45 - 90 * cr;
	  	modelArr[20].position.z = .5 - .5 * cr;
	  	modelArr[20].rotation.y = .8 - .2 * cr;

	  	modelArr[21].position.y = -1 + 5 * cr;
	  	modelArr[21].position.x = 70 - 100 * cr;
	  	modelArr[21].position.z = .5 - .8 * cr;
	  	modelArr[21].rotation.y = .8 - .2 * cr;

	  	modelArr[22].position.y = -1 + 1 * cr;
	  	modelArr[22].position.x = 90 - 110 * cr;

	  	// coffee
	  	modelArr[23].position.y = -74 + posY + (Math.sin( time ) * .3);
	  	modelArr[23].rotation.z =  (Math.sin( time ) * .3);;
	  	modelArr[23].rotation.y += .001;

	  	/*posYoc[posY ovelay control] formula*/
	  	/*posYoc minus the percentage where you want to start*/
	  	/*divided what percentage of posYoc the duration of the animation*/
	  	/*times 100 to make the animation based start is 0 then end on 100*/

	  	// who overlay link
	  	// const whs = window.innerWidth > 768 ? ((posYoc - 15)/10) * 100 : ((posYoc - 16)/10) * 100;
	  	const whs = ((posYoc - 15)/10) * 100;
	  	const wht = whs > 0 ? whs : 0;
	  	whoLink.style.top = `${100 - wht}%`;
	  	if(window.innerWidth > 768){
		  	if(wht > 90 && wht < 110){
		  		whoLink.classList.add('show')
		  	}else{
		  		whoLink.classList.remove('show'); 
		  	}
		  }else{
		  	whoLink.classList.add('show')
		  }

	  	// approach overlay link
	  	const as = ((posYoc - 130)/20) * 100;
	  	const at = as > 0 ? as : 0;
	  	approachLink.style.top = `${100 - at}%`;
	  	if(window.innerWidth > 768){
		  	if(at > 90 && at < 110){
		  		approachLink.classList.add('show')
		  	}else{
		  		approachLink.classList.remove('show');
		  	}
	  	}else{
	  		approachLink.classList.add('show')
	  	}

	  	// purpose overlay link
	  	const ps = ((posYoc - 180)/20) * 100;
	  	const pt = ps > 0 ? ps : 0;
	  	purposeLink.style.top = `${100 - pt}%`;
	  	if(window.innerWidth > 768){
		  	if(pt > 90 && pt < 110){
		  		purposeLink.classList.add('show')
		  	}else{
		  		purposeLink.classList.remove('show');
		  	}
	  	}else{
	  		purposeLink.classList.add('show')
	  	}
	  	
	  	 
	  	// opacity canvas
	  	const sts1 = ((posYoc - 15)/100) * 100;
	  	const stc1 = sts1 > 0 ? sts1 : 0;
	  	section.style.opacity = stc1/100;

	  	// remove canvas overlay
	  	const sts2 = ((posYoc - 225)/10) * 100;
	  	const stc2 = sts2 > 0 ? sts2 : 0;
	  	section.style.top = `${0 - stc2}%`;

	  	if(stc2 > 0){
	  		body.classList.add("bottom");
	  	}else{
	  		body.classList.remove("bottom");
	  	}
	  	renderer.render( scene, camera );
		}
		// MOBILE
		else{
			group[0].rotation.y += .01;

			modelArr[1].rotation.x = .5 + (Math.cos( time ) * .3);
			modelArr[1].rotation.z = -.5 + (Math.sin( time ) * .3);
			modelArr[1].position.y = -2 + (Math.sin( time ) * .3);
			modelArr[1].rotation.y += .01;

			group[1].position.y = -2 + (Math.sin( time ) * .3);
			group[1].rotation.z = -.01 + (Math.cos( time ) * .5);			
			group[1].rotation.y += .01;

			modelArr[2].rotation.z = -.005 + (Math.cos( time ) * .3);
			// modelArr[3].rotation.z = -.5 + (Math.sin( time ) * .3); 
			modelArr[2].position.y = -3 + (Math.sin( time ) * .3);
			modelArr[2].rotation.y += .005;

			sceneArr.forEach(function(elem,i){
				rendererArr[i].render( sceneArr[i], camera );
			});
		}
	}

	window.addEventListener("mousemove", function(e){
		mouse.x = ( e.clientX / window.innerWidth ) * 2 - 1;
		mouse.y = - ( e.clientY / window.innerHeight ) * 2 + 1;
	});

	window.addEventListener("resize", function(){
		camera.aspect = window.innerWidth / window.innerHeight;
		camera.updateProjectionMatrix();

		if(window.innerWidth > 768){
			renderer.setSize( window.innerWidth, window.innerHeight );
		}else{
			rendererArr.forEach(function(elem,i){
				rendererArr[i].setSize( window.innerWidth, window.innerHeight);
			});
		}


		insetWidth = window.innerHeight / 4; // square
		insetHeight = window.innerHeight / 4;

		if(window.innerWidth > 768){
			scrollAnim(curSlide);
		}
	});

	initTHREE();
	if(window.innerWidth > 768){
		castParallax();
	}

}

export default parallax;