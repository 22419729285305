import $ from "jquery";

export default class Navigation{
	menuItems = [];
	btn;
	menu;

	// for footer
	logo;
	locations;
	copyRight;

	navInit(){
		let header = document.querySelector('header');
		let mainMenu = document.createElement('ul');
		let mainLink = [], subLink = [];
		let menu = this.menu;
		let menuBtn = this.btn;

		mainMenu.classList.add('outer-list');
		menu.append(mainMenu);
		this.menuItems.forEach((main,idx)=>{
			// creating main links
			mainLink[idx] = document.createElement('li');
			let isTargetBlnk = main._blank ? 'target="_blank"':'';
			mainLink[idx].innerHTML = `<a href=${main.link} rel='${main.title}' ${isTargetBlnk}>${main.title}</a>`;
			mainMenu.append(mainLink[idx]);
			// if has sublink
			if(main.sublink != ""){
				let subUL = document.createElement('ul');
				subUL.classList.add('inner-list');
				mainLink[idx].append(subUL);

				main.sublink.forEach((sl,sidx)=>{
					subLink[sidx] = document.createElement('li');
					let isSubTargetBlnk = sl._blank ? 'target="_blank"':'';
					subLink[sidx].innerHTML = `<a href=${sl.link} rel='${sl.title}' ${isTargetBlnk}>${sl.title}</a>`;
					subUL.append(subLink[sidx]);
				})
			}

			mainLink[idx].addEventListener('click',(e)=>{
				$(menu).removeClass("show");
				$(menuBtn).removeClass("show");
				$(menu).delay(300).fadeOut(500);
			});
		});

		menuBtn.addEventListener("click",()=>{
			menuBtn.classList.toggle("show");
			if(menu.classList.contains('show')){
				header.classList.remove("turn-white");
				$(menu).removeClass("show");
				$(menu).delay(300).fadeOut(500);
			}else{
				header.classList.add("turn-white");
				$(menu).fadeIn(500,function(){
					$(this).addClass("show");
				});
			}
		});
	}

	footerInit(){
		let menu = this.menu;
		let map = document.createElement('div');
		let loc = this.locations;
		let mnu = this.menuItems;
		let cr = this.copyRight
		map.classList.add('map');
		map.innerHTML = `
		<div class="map-container">
  		<div id="embed-ded-map-canvas">
    		<iframe frameborder="0" src="https://www.google.com/maps/embed/v1/place?q=C3+INTERACTIVE,+Lane+O,+Taguig,+Metro+Manila,+Philippines&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"></iframe>
  		</div>
		</div>`;
		menu.append(map);

		let content = document.createElement('div');
		let sideL = document.createElement('div');
		let sideR = document.createElement('div');
		let mid = document.createElement('div');
		let bot = document.createElement('div');

		content.classList.add('content');
		sideL.classList.add('side-col');
		sideR.classList.add('side-col');
		mid.classList.add('mid-col');
		bot.classList.add('bot-content');

		// logo
		sideL.innerHTML = `<a href="/"><img class="logo" src=${this.logo} rel='C3 logo'></a>`;

		// locations / branches
		loc.forEach((loc,i)=>{
			let col = document.createElement('div');
			if(loc.twinCol){
				col.classList.add('col','twin');
			}else{
				col.classList.add('col');
			}
			
			let title = document.createElement('p');
			title.classList.add('title');
			title.innerHTML = loc.title;

			let address = document.createElement('p');
			address.classList.add('address');
			address.innerHTML = loc.address;

			col.append(title);
			col.append(address);

			mid.append(col)
		});

		//menu
		let mnuUL = document.createElement('ul');
		mnu.forEach((mnu,i)=>{
			let mnuLI = document.createElement('li');
			mnuLI.innerHTML = `<a href='${mnu.link}'>${mnu.title}</a>`;
			mnuUL.append(mnuLI);
		});
		sideR.append(mnuUL);

		content.append(sideL);
		content.append(mid);
		content.append(sideR);
		menu.append(content);

		bot.innerHTML = `<div><a href="#">Privacy Policy</a><span></span><a href="#">Terms of Use</a></div><p>&copy; ${cr}</p>`
		menu.append(bot);
	}	
}